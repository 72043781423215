import React, { Component } from 'react';
import { RootState } from './reducers/RootReducer';
import { setStateEditData, setStateManagerData } from './action/StateManagerAction';
import { setDiamondAvailable, setDiamondDetailsSuccess, setInitialDiamondOption } from './action/DiamondAction';
import { setExpandedRow, setPagination, setTableInitialState } from './action/TableAction';
import {
  setExclusionOption,
  setInitialOption,
  setRingExtraData,
  setRingOptions,
  setRingPrice,
  setSelectedRingDetails
} from './action/RingAction';
import {
  setFooterToggle,
  setInitialFooter,
  setIsShowCartPage,
  setIsShowFooter,
  setStyleID
} from './action/FooterAction';
import { resetFilters, setAfterRingOptions, setFilter } from './action/FilterAction';
import { connect, ConnectedProps } from 'react-redux';
import RouteManager from './RouteManager';
import Loader from './components/Loader/Loader';
import ComponentManger from './ComponentManger';
import {
  setFilterMinMaxProperty,
  setInitialStartWithSettingData,
  setMaximumCaratSize,
  setSelectedProduct,
  setStartWithSettingFilter
} from './action/StartWithSettingFilterAction';
import {
  setDiamondTabIconTrue,
  setHeaderTabDefault,
  setHeaderTabToSetting,
  setPreviousURLPath
} from './action/HeaderAction';
import { setOrderIsExisting } from './action/CartAction';

const mapStateToProps = (state: RootState) => ({
  stateManagerData: state.stateManager,
  diamond:state.diamond,
  footer:state.footer,
  cart: state.cart,
  ring: state.ring,
});

const mapDispatchToProps = {
  setStateManagerData,
  setDiamondDetailsSuccess,
  setExpandedRow,
  setRingOptions,
  setSelectedRingDetails,
  setFilter,
  setIsShowFooter,
  setIsShowCartPage,
  setStartWithSettingFilter,
  setStyleID,
  setFilterMinMaxProperty,
  setRingPrice,
  setStateEditData,
  setDiamondAvailable,
  setRingExtraData,
  setInitialStartWithSettingData,
  setInitialOption,
  setInitialDiamondOption,
  resetFilters,
  setTableInitialState,
  setInitialFooter,
  setPreviousURLPath,
  setExclusionOption,
  setPagination,
  setAfterRingOptions,
  setFooterToggle,
  setHeaderTabDefault,
  setDiamondTabIconTrue,
  setHeaderTabToSetting,
  setSelectedProduct,
  setMaximumCaratSize,
  setOrderIsExisting
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromStateManagerRedux = ConnectedProps<typeof connector>;
type PropsFromComponent = {
  data: {
    app: any;
  };
  changeScreen?: (data: { viewName: string; id: string }) => void;
};

interface ScreenManagerState {
  instanceData: {
    screenManager: ScreenManager;
  };
  previousPath:string;
}

class ScreenManager extends Component<PropsFromStateManagerRedux & PropsFromComponent, ScreenManagerState> {

  constructor(props: PropsFromStateManagerRedux & PropsFromComponent) {
    super(props);
    this.state = {
      instanceData: { screenManager: this },
      previousPath:''
    };
  }

  urlUpdate(url: string): void {
    window.history.pushState({}, '', `#${url}`);
  }

  changeScreen = (data: { viewName: string | undefined;styleId?:string|null, id?: string; uuid?: string, params?:{[key:string]: string} }, type?: string | undefined): void => {
    this.setState({...this.state,previousPath:data.viewName as string})
    let fullPath;
    switch (data.viewName){
      case 'build':
        const buildParam = {...(data.params ?? {}) && data.params};
        fullPath = `${data.viewName}${data.styleId ? `/styleId=${data.styleId}${(buildParam && Object.keys(buildParam).length > 0) ? `&${new URLSearchParams(buildParam)}` : ''}` : ''}${data.id ? `/diamondId=${data.id}` : ''}`;

        // localStorage.setItem('rpid', JSON.stringify(data.id));
        break;
      case 'edit':
        fullPath = 'summary';
        // localStorage.setItem('rpid', JSON.stringify(data.id));
        break;
      case 'dyo':
      case 'setting':
        const item = {...(data.params ?? {}) && data.params};
        fullPath = `${data.viewName}${data.styleId ? `/styleId=${data.styleId}${(item && Object.keys(item).length > 0) ? `&${new URLSearchParams(item)}` : ''}` : ''}${data.id ? `/diamondId=${data.id}` : ''}`;
        break;
      case 'details':
        fullPath = `${data.viewName}/diamondId=${data.id}`;
        break;
      // case 'setting':
      //   fullPath = `${data.viewName}${data.styleId?`/styleId=${data.styleId}`:''}${data.id? `/diamondId=${data.id}`:''}`;
      //   break;
      default:
        fullPath = data.viewName;
    }
    if(type !== 'popstate'){
      this.urlUpdate(fullPath as string);
    }
    const path = RouteManager.findPath(`#${data.viewName}`, !!data.styleId);

    const managerData = {
      path,
      id: data.id || ''
    }
    Object.entries(managerData).forEach(([key,value]) => this.props.setStateManagerData(key,value));
  };

  componentDidMount(): void {
    this.handleUrlChange();
    this.props.setPreviousURLPath(document.referrer);
    localStorage.setItem('previousPath',JSON.stringify(document.referrer));
    window.addEventListener('popstate', this.handleUrlChange);
  }

  componentWillUnmount(): void {
    window.removeEventListener('popstate', this.handleUrlChange);
  }

  shouldComponentUpdate(prevProps: PropsFromStateManagerRedux): boolean {
    return prevProps.stateManagerData.options.path !== this.props.stateManagerData.options.path;
  }

  parseURL(url: string): { command: string; params: { [key: string]: string } } {
    const fragment = url?.split('#')[1];
    if (!fragment) {
      this.changeScreen({viewName:'home'});
      // throw new Error('Invalid URL format: Missing fragment identifier');
    }
    const parts = fragment?.split('/');
    const command = parts?.find(part => part) || '';
    if (parts && parts.length > 1) {
      const queryParts = parts.slice(1).join('&')?.split('&');
      const params: { [key: string]: string } = {};
      for (const queryPart of queryParts) {
        const [key, value] = queryPart?.split('=');
        params[key] = value;
      }
      return { command, params };
    } else {
      return { command, params: {} };
    }
  }
  getSKU = async (uuid:string) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_GET_DESIGN_DATA}?hkuuid=${uuid}&page_size=200`);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();
      const {left_band_retail_price,right_band_retail_price,ring_retail_price,band_tcw,ring_tcw} =result.pricing_data;
      const {available,page_number} = result?.inventoryAvailabilities;
      if(result){
        this.props.setOrderIsExisting(result.is_order_exist);
        this.props.setStateEditData(result);
        (available || result.is_order_exist) && this.props.setDiamondDetailsSuccess(result?.diamond_details);
        // this.props.setExpandedRow(0,result?.diamond_details.id as string);
        this.props.setIsShowFooter(true);
        this.props.setStyleID(result.sku.split('-')[0]);
        this.props.setRingPrice({ring_price:ring_retail_price, left_band_price:left_band_retail_price, right_band_price:right_band_retail_price,bands_tcw:band_tcw,ring_tcw:ring_tcw});
        const { exclusionOption , ...rest}  = result.ring_extra_data ;
        this.props.setRingExtraData(rest);
        this.props.setExclusionOption(exclusionOption);
        if(Object.keys(result.setting_filter_options).length >0 && Object.keys(result.setting_filter_options).includes('Setting_Price')){
          const settingPrice = result.setting_filter_options.Setting_Price.split('-');
          this.props.setFilterMinMaxProperty({
            Setting_Price: {min : +settingPrice[0], max: +settingPrice[1]}
          })
        }
        const {selectedProduct,maximumCaratSize} = result?.setting_filter_options;
        this.props.setMaximumCaratSize(maximumCaratSize);
        this.props.setSelectedProduct(selectedProduct);
        const {options, afterRingSelectedOptions} = result?.diamond_filter_options ;
        this.props.setAfterRingOptions(afterRingSelectedOptions);
        Object.entries(result.ring_options_code).forEach(([key,value])=> this.props.setRingOptions(key,value as string));
        Object.entries(result?.setting_details).forEach(([key,value])=> this.props.setSelectedRingDetails(key,value as string));
        Object.entries(options).forEach(([key,value])=> this.props.setFilter(key,value as string));
        Object.entries(result?.setting_filter_options).forEach(([key,value])=> this.props.setStartWithSettingFilter(key,value as string));
        Object.entries(result?.table_row_id).forEach(([key,value])=> this.props.setExpandedRow(+key,value as string));
        this.props.setDiamondAvailable(available);
        this.props.setPagination(0, {pageSize: 200, pageIndex:page_number})
        return result;

      }
    }catch (e) {
      console.log(e)
    }

  }
  handleUrlChange = async(e?: PopStateEvent): Promise<void> => {
    try {
      const parsedData = this.parseURL(window.location.href as string);
      const { command, params } = parsedData;
      let { start_with, rpid, hkuuid: uuid,diamondId:id,styleId,ring_style,shape,type,carat,crown,side,metal,crown_metal,size,band } = params;
      const paramsObj = {
        ...(ring_style && {ring_style}),
        ...(shape && {shape}),
        ...(type && {type}),
        ...(carat && {carat}),
        ...(crown && {crown}),
        ...(side && {side}),
        ...(metal && {metal}),
        ...(crown_metal && {crown_metal}),
        ...(size && {size}),
        // ...(band && {band})
      };
      const viewName = start_with && ['diamond', 'setting','dyo'].includes(start_with.toLowerCase())
        ? start_with.toLowerCase()
        : command;
      styleId = start_with && start_with.toLowerCase() === 'dyo' ? 'RB001' : styleId;
      if(["build","edit"].includes(command)){
        ['rpid','builder_mode','design_uuid'].forEach(key =>localStorage.removeItem(key));
      }
      if(uuid || JSON.parse(localStorage.getItem('design_uuid') as string)){
        //@ts-ignore
        const result = await this.getSKU(uuid || JSON.parse(localStorage.getItem('design_uuid')));
        styleId = result?.sku?.split('-')[0];
        id = result.diamond_details.id;
      }
      if(e){
        if((['setting','diamond','details'].includes(command)) && this.state.previousPath === 'dyo'){
           this.props.setInitialOption();
           if(command === 'diamond' && this.props.footer.toggle){
            await  this.props.setHeaderTabDefault();
           }else if(command === 'setting' && this.props.footer.toggle){
            await this.props.setDiamondTabIconTrue('setting');
           }
          if (command === 'setting' && params.styleId) {
            window.postMessage({ action: 'handshakeIFrame' });
          }
           this.props.setInitialFooter();
        } else if (command === 'diamond' && this.state.previousPath === 'setting') {
          this.props.setInitialStartWithSettingData();
        } else if (command === 'dyo' && this.state.previousPath === 'setting' && params.styleId) {
          this.props.setInitialOption();
          window.postMessage({ action: 'handshakeIFrame' });
        } else if (command === 'dyo' && this.state.previousPath === 'diamond') {
          if(this.props.footer.toggle){
            this.props.setHeaderTabToSetting('dyo');
            this.props.setFooterToggle();
          }
          this.props.setInitialDiamondOption();
          this.props.setTableInitialState();
          this.props.resetFilters();
        }else if(command === 'diamond' && this.state.previousPath === 'details'){
          this.props.setInitialDiamondOption();
          if(!(Object.keys(this.props.ring.options).length > 0)){
            this.props.setInitialFooter();
          }
        }
      }

      if(command === "build"){
          this.changeScreen({ viewName, uuid ,id: id,styleId,params:paramsObj}, '');
        this.props.setIsShowFooter(false);
      } else if(["edit","summary"].includes(command)){
        if(this.props.diamond.isDiamondAvailable || this.props.cart.isOrderExist){
          this.props.setIsShowCartPage();
          this.props.setIsShowFooter(true);
          this.changeScreen({ viewName: 'edit' });
        } else {
          this.changeScreen({ viewName:'diamond'});
        }
      } else {
        this.changeScreen({ viewName, id: id, uuid ,styleId,params:paramsObj}, e?.type);
      }
      const sessionStorageObject = {
        'rpid': rpid ,
        'builder_mode': command,
        'design_uuid': uuid
      }
      if(['build','edit'].includes(command)) {
        Object.entries(sessionStorageObject).forEach(([key, value]) => {
          if (value) {
            localStorage.setItem(key, JSON.stringify(value));
          } else {
            localStorage.removeItem(key);
          }
        })
      }/*else{
        Object.entries(sessionStorageObject).forEach(([key, value]) => (
            localStorage.removeItem(key)
        ))
      }*/
    } catch (error) {
      console.error('Error parsing URL:', error);
    }
  };

  render(): JSX.Element {
    const { stateManagerData: { options: { path } } } = this.props;
    let ViewComponent: any | null = null;
    if (path) {
      ViewComponent = ComponentManger.findComponent(path as string);
    }

    return (
        <React.Suspense fallback={<div style={{height : '100vh'}}><Loader /></div>}>
        {ViewComponent && (
          //@ts-ignore
          <ViewComponent instanceData={this.state.instanceData} />
        )}
      </React.Suspense>
    );
  }
}

export default connector(ScreenManager);
